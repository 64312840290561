/*	========================================================================
	*	DOM-based	Routing
	*	Based	on	http://goo.gl/EUTi53	by	Paul	Irish
	*
	*	Only	fires	on	body	classes	that	match.	If	a	body	class	contains	a	dash,
	*	replace	the	dash	with	an	underscore	when	adding	it	to	the	object	below.
	*
	*	.noConflict()
	*	The	routing	is	enclosed	within	an	anonymous	function	so	that	you	can
	*	always	reference	jQuery	with	$,	even	when	in	.noConflict()	mode.
	*	========================================================================	*/

/*
=======================================
//! Setup Variables
=======================================
*/

var $=jQuery,windowHeight = $(window).height(),windowWidth = $(window).width(),beforeElHeight,baseUrl = '/wp-content/themes/alt-fruity/dist/scripts/';

/*
=======================================
//! Resize Element Function
=======================================
*/

function resizeElement($element, $divide, $axis, $excess){
	if($axis === 'height'){$($element).css('height', ((windowHeight / $divide) + $excess) + 'px').css('top', -$excess + 'px');}
	else if($axis === 'width'){$($element).css('width', (windowWidth / $divide) + 'px');}
	else{console.log('$axis not defined.');}
}

/*
=======================================
//! Square Off Elements
=======================================
*/

function squareElements($element, $axis){
	var $thisHeight = $element.height, $thisWidth = $element.width;
	if($axis === 'height'){
		$element.width($thisHeight);
	}
	else{
		$element.height($thisWidth);
	}
}

/*
=======================================
//! Setup Position Of Element Function
=======================================
*/

function setPosition($before, $element, $excess){
	beforeElHeight = $($before).height();
	$($element).css('marginTop', (beforeElHeight - $excess) + 'px');
}

/*
=======================================
//! NOTES!
=======================================
*/

/*
=======================================
//! PLEASE PLEASE PLEASE PLEASE DO NOT USE LET, Babel breaks for some reason. I'll update when possible.
=======================================
*/

(function($)	{

		var	Ctrl	=	{
				//	All	pages
				'common':	{
						init:	function()	{
							
						},
						finalize:	function()	{							
							
						}
				},					
				//	Home	page
				'home':	{
						init:	function()	{
								
						},
						finalize:	function()	{
							
						}
				},
				
				'about_us':	{
						init:	function()	{
								
						}
				},
				
				'news': {
						init: function(){
							
						},
						
						finalize: function(){
						
						}
				}
		};

		//	The	routing	fires	all	common	scripts,	followed	by	the	page	specific	scripts.
		//	Add	additional	events	for	more	control	over	timing	e.g.	a	finalize	event
		var	UTIL	=	{
				fire:	function(func,	funcname,	args)	{
						var	fire;
						var	namespace	=	Ctrl;
						funcname	=	(funcname	===	undefined)	?	'init'	:	funcname;
						fire	=	func	!==	'';
						fire	=	fire	&&	namespace[func];
						fire	=	fire	&&	typeof	namespace[func][funcname]	===	'function';

						if	(fire)	{
								namespace[func][funcname](args);
						}
				},
				loadEvents:	function()	{
						//	Fire	common	init	JS
						UTIL.fire('common');

						//	Fire	page-specific	init	JS,	and	then	finalize	JS
						$.each(document.body.className.replace(/-/g,	'_').split(/\s+/),	function(i,	classnm)	{
								UTIL.fire(classnm);
								UTIL.fire(classnm,	'finalize');
						});

						//	Fire	common	finalize	JS
						UTIL.fire('common',	'finalize');
				}
		};

		//	Load	Events
		$(document).ready(UTIL.loadEvents);

})(jQuery);	//	Fully	reference	jQuery	after	this	point.
